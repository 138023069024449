import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Android で HTML をいい感じで表示できるようにした話",
  "date": "2018-10-30T10:07:17.000Z",
  "slug": "entry/2018/10/30/190717",
  "tags": ["medley"],
  "hero": "./2018_10_30.png",
  "heroAlt": "andorid = html"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。開発本部の`}{` `}<a parentName="p" {...{
        "href": "https://clinics.medley.life/karte"
      }}>{`CLINICS カルテ`}</a>{` `}{`の開発を担当している @seka です。メドレーでは貴重な (?) エンジニアの若者枠として日々奮闘しております。`}</p>
    <p>{`今回、開発本部で定期的に開催している勉強会「TechLunch」で、「Android で HTML をいい感じで表示できるようにした話」 という題で発表しましたので、その内容について紹介させていただきます。`}</p>
    <h1>{`1. きっかけ`}</h1>
    <p><a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`医師たちがつくるオンライン医療事典 MEDLEY (メドレー)`}</a>{` `}{`をアプリ化することができるか検証してみて欲しいという相談を受け、Android のモックを作成することになりました。`}</p>
    <p>{`アプリらしい UI を目指して開発を進めていたのですが、MEDLEY では病気記事が CMS などに見られるような HTML 形式で管理されており、そのまま表示してもイメージしたようなデザインが実現できないかも...という課題に直面しました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181030/20181030115802.png",
        "alt": "f:id:medley_inc:20181030115802p:plain",
        "title": "f:id:medley_inc:20181030115802p:plain"
      }}></img></p>
    <h1>{`2. HTML を表示するまで`}</h1>
    <p>{`いくつかのステップに分解して、HTML の要素を Android のコンポーネントに置換していくことで対応する方針を立て、その実現可能性を調べました。大まかなフローはこんな感じです。`}</p>
    <ol>
      <li parentName="ol">{`事前に HTML と Android のコンポーネントの対応を決める`}</li>
      <li parentName="ol">{`HTML を Kotlin でも扱える形式に変換する`}</li>
      <li parentName="ol">{`HTML の要素を探索する`}</li>
      <li parentName="ol">{`マッチした要素を Android のコンポーネントで置き換える それぞれのフローについて解説していきます。`}</li>
    </ol>
    <h2>{`1. 事前に HTML と Android のコンポーネントの対応表を作る`}</h2>
    <p>{`簡単にですが下表のような対応を決めます。(検証段階だったので、いくつかの要素は省略しています)`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181029/20181029222937.png",
        "alt": "f:id:medley_inc:20181029222937p:plain",
        "title": "f:id:medley_inc:20181029222937p:plain"
      }}></img></p>
    <h2>{`2. HTML を Kotlin で扱える形式に変換する`}</h2>
    <p>{`HTML を生の String として操作するのは流石に辛いので、Kotlin でも扱いやすいような形に変換します。`}</p>
    <p>{`今回は`}{` `}<a parentName="p" {...{
        "href": "https://github.com/jhy/jsoup?utm_source=android-arsenal.com&utm_medium=referral&utm_campaign=191"
      }}>{`jhy/jsoup`}</a>{` `}{`という便利そうなライブラリを見つけたため、これを利用することにしました。`}</p>
    <p>{`jsoup は Java 製の HTML パーサーで`}{` `}<a parentName="p" {...{
        "href": "https://github.com/jhy/jsoup?utm_source=android-arsenal.com&utm_medium=referral&utm_campaign=191#example"
      }}>{`example`}</a>{` `}{`にもあるように HTML を Kotlin でも扱いやすい形式に変換することができます。`}</p>
    <p>{`下記の例は Wikipedia Root Node から小要素を探索していく様子です。`}</p>
    <p>{`HTML タグの情報・親要素・内容のテキストを利用したいため保持しておきます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "kotlin"
    }}><pre parentName="div" {...{
        "className": "language-kotlin"
      }}><code parentName="pre" {...{
          "className": "language-kotlin"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`val`}</span>{` doc `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Jsoup`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`connect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal singleline"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"https://en.wikipedia.org/"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`get`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
doc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`childNode`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`childNode`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`childNode`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/*
{Element@5739} "<div id="mw-page-base" class="noprint"></div>"
  attributes = {Attributes@5755}
  baseUri = "https://en.wikipedia.org/wiki/Main_Page"
  childNodes = {Collections$EmptyList@5720}  size = 0
  shadowChildrenRef = null
  tag = {Tag@5756}
  canContainInline = true
  empty = false
  formList = false
  formSubmit = false
  formatAsBlock = true
  isBlock = true
  preserveWhitespace = false
  selfClosing = false
  tagName = "div"
  parentNode = {Element@5729}
  siblingIndex = 1
*/`}</span></code></pre></div>
    <h2>{`3. HTML の要素を探索する`}</h2>
    <p>{`HTML の要素を探索する 再帰を利用して Root Node から小要素を探索し、jsoup で置き換えていきます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "kotlin"
    }}><pre parentName="div" {...{
        "className": "language-kotlin"
      }}><code parentName="pre" {...{
          "className": "language-kotlin"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`HTMLConverter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`val`}</span>{` html`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` String`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fun`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`parse`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`val`}</span>{` body `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Jsoup`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`parse`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`html`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`normalise`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`body`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`inspect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`body`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ElementViewHolder`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fun`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`inspect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`parent`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Element`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      parent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`children`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`parent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`children`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`isEmpty`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span><span parentName="code" {...{
            "className": "token label symbol"
          }}>{`@forEach`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`inspect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`it`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`4. マッチした要素を Android のコンポーネントで置き換える`}</h2>
    <p>{`事前に定義した方法に従って、HTML をそれぞれ対応する Android コンポーネントに変換していきます。`}</p>
    <p>{`Img 要素の場合:`}</p>
    <p>{`Glide を利用して画像を非同期で取得し ImageView にラップする。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "kotlin"
    }}><pre parentName="div" {...{
        "className": "language-kotlin"
      }}><code parentName="pre" {...{
          "className": "language-kotlin"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fun`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`convertImage`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`el`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Element`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` View`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`val`}</span>{` url `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` HttpUrl`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`parse`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`el`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`absUrl`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal singleline"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"src"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ImageView`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`apply`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    layoutParams `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` LinearLayout`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`LayoutParams`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`MATCH_PARENT`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` WRAP_CONTENT`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`val`}</span>{` header `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` LazyHeaders`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Builder`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addHeader`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal singleline"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"Content-Type"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal singleline"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"image/bmp"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`build`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`val`}</span>{` glideUrl `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`GlideUrl`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`url`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`url`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` header`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    Glide`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`with`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`asBitmap`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`load`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`glideUrl`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`into`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Table 要素の場合:`}</p>
    <p>{`Table と TableRow をそれぞれ作成して合成する。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "kotlin"
    }}><pre parentName="div" {...{
        "className": "language-kotlin"
      }}><code parentName="pre" {...{
          "className": "language-kotlin"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fun`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`parseTable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`el`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Element`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` View`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`TableLayout`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`apply`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    layoutParams `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` LinearLayout`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`LayoutParams`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`MATCH_PARENT`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` WRAP_CONTENT`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fun`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`createTableRow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` View`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`TableRow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fun`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`composeTableRow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`self`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` TableRow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` parent`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` TableLayout`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Boolean `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  table`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addView`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h1>{`Android Emulator で表示してみる`}</h1>
    <p>{`上記の方法で作成したモックがこちらになります。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181029/20181029223014.gif",
        "alt": "f:id:medley_inc:20181029223014g:plain",
        "title": "f:id:medley_inc:20181029223014g:plain"
      }}></img></p>
    <p>{`ただ HTML を表示するだけであれば WebView を利用すればもっと楽に表示することもできますが、UI をカスタマイズしたい場合にはデータに変更を加えなければいけません。`}</p>
    <p>{`今回のようなアプローチをとることで、もともとある HTML のデータを壊すことなく Android に適したデザインを実現しやすくなったのではないでしょうか。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`コミュ障故に人前で話すことを避けてきたのですが、 TechLunch という機会をいただき Android なネタを発表をさせていただきました。`}</p>
    <p>{`今回作成したモックは技術検証目的であるためリリースの予定はありませんが、発表のために書いた実装例は`}{` `}<a parentName="p" {...{
        "href": "https://gist.github.com/seka/46b042df4ae8b37b86edce3f5ff83b9a"
      }}>{`seka/HTMLConverter.kt`}</a>{` `}{`として公開しています。`}</p>
    <p>{`このようなアプローチを取る機会は少ないと思いますが、読んでくださった方の力になれれば幸いです！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      